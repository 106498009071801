
import { convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId } from '~/helpers/util'
export default {
  name: 'SectionHero',
  props: {
    href: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    },
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    extra: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeThumb: 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0
    }
  },
  computed: {
    sliderTitleLabel() {
      return `${this.data[this.activeThumb].title} ${this.data[this.activeThumb].subtitle}`
    },
    sliderButtonUrl() {
      return this.data[this.activeThumb].href
    },
    heading() {
      return this.extra.heading
    },
    welcome() {
      return this.extra.welcomeMessage
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections
      if (sections && sections.length > 1) {
        const targetSection = sections[this.orderNo]
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type)
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    handleThumbChange(itemId) {
      const temp = itemId.split('-')
      this.activeThumb = parseInt(temp[1])
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight
    }
  }
}
